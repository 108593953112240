<footer class="footer-section">
    <div class="container">
        <div class="footer-content">
            <div>
                <ul class="top-bar-menu-right footer-menu">
                    <li> <a> Legal</a></li>
                    <li> <a> Terms and Conditions </a></li>
                    <li> <a> Privacy Policy </a></li>
                    <li> <a> Contact Us </a></li>
                </ul>
                <p class="copy-right-text mt-2 mb-0">© 2022 Mohawk Industries, Inc.
                </p>
            </div>
            <a href="">
                <img src="/assets/images/logo.svg">
            </a>
        </div>
    </div>
</footer>