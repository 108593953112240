<header class="site-header">
    <div class="container">
        <div class="header-content">
            <div class="logo">
                <a [routerLink]="['/']" routerLinkActive="router-link-active">
                    <img src="/assets/images/logo.svg" alt="Logo">
                </a>
            </div>

            <div class="header-text">
                Communication Matrix Service Level Agreement(SLA) Policy
            </div>
        </div>
    </div>
</header>